/**
 * We will use use the regular onClick handler as a replacement for onPress until
 * having a decent fix for those issues:
 * @see https://github.com/necolas/react-native-web/issues/731
 * @see https://github.com/necolas/react-native-web/issues/1219
 */

import React, {
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from 'react';
import {
  Platform,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native';

export interface Props extends PropsWithChildren<TouchableOpacityProps> {
  onClick?: MouseEventHandler<Element>;
  onMouseEnter?: MouseEventHandler<Element>;
  onMouseLeave?: MouseEventHandler<Element>;
}

const Touchable = ({
  children,
  onPress,
  onClick,
  ...touchableOpacityProps
}: Props): ReactElement => {
  const touchableProps: TouchableOpacityProps =
    Platform.OS === 'web'
      ? {
          ...touchableOpacityProps,
          // @ts-ignore web specific
          onClick(event: MouseEvent): void {
            if (touchableOpacityProps.disabled) return;

            // @ts-ignore will take precedence when defined over onPress
            if (onClick) onClick(event);

            // @ts-ignore fall back to onPress
            if (onPress) onPress();
          },
        }
      : { ...touchableOpacityProps, onPress };

  return <TouchableOpacity {...touchableProps}>{children}</TouchableOpacity>;
};

export default Touchable;
