import kebabCase from 'lodash/kebabCase';
import { Text, TextProps } from 'react-native';

import { Color, StyleDictionary, ThemeProps } from '../theme';
import { platformSpecific, styledWithoutProps } from '../theme/helper';

interface StyledTextProps extends TextProps, ThemeProps {
  styleKey: string;
  color?: Color;
}

function computeTextStyle({ theme, color, styleKey }: StyledTextProps): string {
  let textStyle: StyleDictionary = theme.textStyle[styleKey];

  // Default to dark when there is no color defined
  if (!color && !textStyle.color) {
    textStyle = { ...textStyle, color: theme.color.dark };
  }

  // Theme color
  if (!color && textStyle.color instanceof Function) {
    textStyle = { ...textStyle, color: textStyle.color({ theme }) };
  }

  // Override color when provided
  if (color) {
    textStyle = { ...textStyle, color: theme.color[color] };
  }

  return Object.keys(textStyle).reduce(
    (style: string, textStyleProp: string): string => `
      ${style}
      ${kebabCase(textStyleProp)}: ${textStyle[textStyleProp]};
    `,
    '',
  );
}

export const StyledText = styledWithoutProps<StyledTextProps>(
  Text,
  'styleKey',
  'color',
)`
  ${computeTextStyle}
`;

export interface LoadableTextProps extends ThemeProps, StyledTextProps {
  isLoading: boolean;
}

export const LoadableText = styledWithoutProps<LoadableTextProps>(
  StyledText,
  'isLoading',
)`
  background-color: ${({ theme }: LoadableTextProps): string =>
    theme.color[Color.GRAY_300]};
  color: ${({ theme }: LoadableTextProps): string =>
    theme.color[Color.GRAY_300]};
  ${platformSpecific.web`
    width: max-content;
  `}
`;
