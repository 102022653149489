import isEmpty from 'lodash/isEmpty';
import React, { FC, ReactElement, useState } from 'react';

import Badge from '../Badge';
import Symbol from '../Symbol';
import Text from '../Text';
import { Color, TextStyle } from '../theme';
import {
  colorAssociation,
  SMALL_SYMBOL_SCALE,
  MEDIUM_SYMBOL_SCALE,
  LARGE_SYMBOL_SCALE,
  ACTIVE_OPACITY,
  DEFAULT_COLOR,
  retentionOffset,
} from './helper';
import {
  BadgeContainer,
  HelperText,
  TextContainer,
  SymbolContainer,
  SymbolOnlyTouchableContainer,
  RowWrapper,
  WithHelperTextContainer,
} from './styledComponents';
import { ButtonProps } from './typings';

const ButtonWithHelperText: FC<ButtonProps> = ({
  helperText,
  title,
  fullWidth = false,
  color = DEFAULT_COLOR,
  symbolKey,
  tiny = false,
  small = false,
  large = false,
  outlined = false,
  inGroupRight = false,
  inGroupLeft = false,
  inGroupCenter = false,
  cappedHeight = false,
  notificationCounter,
  ...touchableContainerProps
}: ButtonProps): ReactElement => {
  let symbolScale: number = small ? SMALL_SYMBOL_SCALE : MEDIUM_SYMBOL_SCALE;
  symbolScale = large ? LARGE_SYMBOL_SCALE : symbolScale;
  const symbolOnly: boolean = !!symbolKey && isEmpty(title);
  const Container = symbolOnly
    ? SymbolOnlyTouchableContainer
    : WithHelperTextContainer;
  const { textColor, backgroundColor } = colorAssociation({
    color,
    disabled: touchableContainerProps.disabled,
    light: touchableContainerProps.light,
  });

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container
      testID="withHelperTextContainer"
      {...touchableContainerProps}
      activeOpacity={ACTIVE_OPACITY}
      fullWidth={fullWidth}
      borderColor={textColor}
      backgroundColor={backgroundColor}
      tiny={tiny}
      small={small}
      large={large}
      outlined={outlined}
      inGroupLeft={inGroupLeft}
      inGroupCenter={inGroupCenter}
      inGroupRight={inGroupRight}
      pressRetentionOffset={{
        top: retentionOffset,
        left: retentionOffset,
        bottom: retentionOffset,
        right: retentionOffset,
      }}
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={(): void => setIsHovered(false)}
      cappedHeight={cappedHeight}
    >
      <RowWrapper hidden={isHovered}>
        {notificationCounter && notificationCounter > 0 && (
          <BadgeContainer>
            <Badge counter={notificationCounter} />
          </BadgeContainer>
        )}
        {symbolKey && (
          <SymbolContainer symbolOnly={symbolOnly} large={large} small={small}>
            <Symbol
              symbolKey={symbolKey}
              scale={symbolScale}
              color={textColor}
            />
          </SymbolContainer>
        )}
        <TextContainer>
          {title && (
            <Text styleKey={TextStyle.BUTTON_LABEL} color={textColor}>
              {title}
            </Text>
          )}
          <HelperText styleKey={TextStyle.BODY_3} color={textColor}>
            {helperText}
          </HelperText>
        </TextContainer>
      </RowWrapper>
      <RowWrapper hidden={!isHovered}>
        <HelperText styleKey={TextStyle.BUTTON_LABEL} color={textColor} bold>
          {helperText}
        </HelperText>
      </RowWrapper>
    </Container>
  );
};

ButtonWithHelperText.defaultProps = {
  color: Color.PRIMARY,
};

export default ButtonWithHelperText;
