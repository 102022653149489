import React, { FC, ReactElement, ReactNode } from 'react';
import { TextProps } from 'react-native';
import { withTheme } from 'styled-components';

import { Color, TextStyle, ThemeProps } from '../theme';
import { LoadableText, StyledText } from './styledComponents';

export interface Props extends ThemeProps, TextProps {
  children: ReactNode;
  styleKey?: TextStyle;
  color?: Color;
  isLoading?: boolean;
}

const Text: FC<Props> = ({
  children,
  theme,
  styleKey = TextStyle.BODY_1,
  color,
  isLoading,
  ...textProps
}: Props): ReactElement =>
  isLoading ? (
    <LoadableText
      styleKey={styleKey}
      color={color}
      isLoading={!!isLoading}
      maxFontSizeMultiplier={theme.maxFontSizeMultiplier}
      {...textProps}
    >
      {children}
    </LoadableText>
  ) : (
    <StyledText
      styleKey={styleKey}
      color={color}
      maxFontSizeMultiplier={theme.maxFontSizeMultiplier}
      {...textProps}
    >
      {children}
    </StyledText>
  );

export default withTheme(Text);
