import { Color, PrimaryColor, scaleUnitLess, TextStyle } from '../theme';
import { ButtonProps } from './typings';

type ButtonColorAssociation = {
  backgroundColor: Color;
  textColor: Color;
};

function regularColorAssociation({
  color,
  disabled,
}: Pick<ButtonProps, 'color' | 'disabled'>): ButtonColorAssociation {
  if (disabled) {
    return {
      backgroundColor: Color.GRAY_200,
      textColor: Color.GRAY_500,
    };
  }

  switch (color) {
    case Color.PRIMARY:
      return {
        backgroundColor: Color.PRIMARY,
        textColor: Color.LIGHT,
      };
    case Color.SECONDARY:
      return {
        backgroundColor: Color.PRIMARY_LIGHT,
        textColor: Color.PRIMARY,
      };
    case Color.SECONDARY_ALT:
      return {
        backgroundColor: Color.SECONDARY,
        textColor: Color.LIGHT,
      };
    case Color.LIGHT:
      return {
        backgroundColor: Color.LIGHT,
        textColor: Color.PRIMARY,
      };
    case Color.SUNNY:
      return {
        backgroundColor: Color.SUNNY,
        textColor: Color.LIGHT,
      };
    case Color.SUNNY_LIGHT:
      return {
        backgroundColor: Color.SUNNY_LIGHT,
        textColor: Color.SUNNY,
      };
    case Color.GRAY_200:
      return {
        backgroundColor: Color.GRAY_200,
        textColor: Color.GRAY_500,
      };
    case Color.DARK:
      return {
        backgroundColor: Color.DARK_LIGHT,
        textColor: Color.LIGHT,
      };
    case Color.QUATERNARY:
      return {
        backgroundColor: Color.QUATERNARY,
        textColor: Color.LIGHT,
      };
    case Color.QUATERNARY_LIGHT:
      return {
        backgroundColor: Color.QUATERNARY_LIGHT,
        textColor: Color.QUATERNARY,
      };
    case Color.SUCCESS_ALT:
      return {
        backgroundColor: Color.SUCCESS,
        textColor: Color.LIGHT,
      };
    case Color.DANGER_ALT:
      return {
        backgroundColor: Color.DANGER,
        textColor: Color.LIGHT,
      };
    default:
      return {
        backgroundColor: `${color}Light` as Color,
        textColor: color!,
      };
  }
}

export function colorAssociation(
  options: Pick<ButtonProps, 'color' | 'disabled' | 'light'>,
): ButtonColorAssociation {
  if (!options.light) return regularColorAssociation(options);

  return {
    backgroundColor: Color.LIGHT,
    textColor: options.color!,
  };
}

export const SMALL_SYMBOL_SCALE = 0.75;
export const MEDIUM_SYMBOL_SCALE = 0.875;
export const LARGE_SYMBOL_SCALE = 1.25;
export const ACTIVE_OPACITY = 0.5;
export const DEFAULT_COLOR: PrimaryColor = Color.PRIMARY;
export const retentionOffset = scaleUnitLess(100);

export function getTextStyleKey({ textStyle, tiny }: ButtonProps): TextStyle {
  if (tiny) return TextStyle.TINY_BUTTON_LABEL;
  if (textStyle) return textStyle;
  return TextStyle.BUTTON_LABEL;
}
