export interface Sizeable {
  fullWidth?: boolean;
  width?: number | string;
}

export function containerWidth(props: Sizeable): string | number {
  const { fullWidth, width } = props;
  if (fullWidth) return '100%';
  if (width) return width;
  return 'auto';
}
