import isEmpty from 'lodash/isEmpty';
import React, { ReactElement } from 'react';

import Badge from '../Badge';
import Symbol from '../Symbol';
import { Color, PrimaryColor } from '../theme';
import ButtonWithHelperText from './ButtonWithHelperText';
import {
  colorAssociation,
  SMALL_SYMBOL_SCALE,
  MEDIUM_SYMBOL_SCALE,
  LARGE_SYMBOL_SCALE,
  ACTIVE_OPACITY,
  DEFAULT_COLOR,
  retentionOffset,
  getTextStyleKey,
} from './helper';
import {
  BadgeContainer,
  StyledText,
  SymbolContainer,
  SymbolOnlyTouchableContainer,
  TouchableContainer,
} from './styledComponents';
import { ButtonProps } from './typings';

const Button = ({
  title,
  width,
  fullWidth = false,
  color = DEFAULT_COLOR,
  symbolKey,
  tiny = false,
  small = false,
  large = false,
  outlined = false,
  inGroupRight = false,
  inGroupCenter = false,
  inGroupLeft = false,
  notificationCounter,
  textStyle,
  hasBoldText,
  ...touchableContainerProps
}: ButtonProps): ReactElement => {
  let symbolScale: number = small ? SMALL_SYMBOL_SCALE : MEDIUM_SYMBOL_SCALE;
  symbolScale = large ? LARGE_SYMBOL_SCALE : symbolScale;
  const symbolOnly: boolean = !!symbolKey && isEmpty(title);
  const Container = symbolOnly
    ? SymbolOnlyTouchableContainer
    : TouchableContainer;
  const { textColor, backgroundColor } = colorAssociation({
    color,
    disabled: touchableContainerProps.disabled,
    light: touchableContainerProps.light,
  });

  return (
    <Container
      testID="container"
      {...touchableContainerProps}
      activeOpacity={ACTIVE_OPACITY}
      fullWidth={fullWidth}
      width={width}
      borderColor={textColor}
      backgroundColor={backgroundColor}
      tiny={tiny}
      small={small}
      large={large}
      outlined={outlined}
      inGroupLeft={inGroupLeft}
      inGroupCenter={inGroupCenter}
      inGroupRight={inGroupRight}
      pressRetentionOffset={{
        top: retentionOffset,
        left: retentionOffset,
        bottom: retentionOffset,
        right: retentionOffset,
      }}
    >
      {notificationCounter && notificationCounter > 0 && (
        <BadgeContainer>
          <Badge counter={notificationCounter} />
        </BadgeContainer>
      )}
      {symbolKey && (
        <SymbolContainer symbolOnly={symbolOnly} large={large} small={small}>
          <Symbol symbolKey={symbolKey} scale={symbolScale} color={textColor} />
        </SymbolContainer>
      )}
      {title && (
        <StyledText
          styleKey={getTextStyleKey({ textStyle, tiny })}
          color={textColor as PrimaryColor}
          hasBoldText={!!hasBoldText}
        >
          {title}
        </StyledText>
      )}
    </Container>
  );
};

Button.defaultProps = {
  color: Color.PRIMARY,
};

Button.WithHelperText = ButtonWithHelperText;

export default Button;
